<template>
    <div>
        <!-- Title -->
        <div class="title">
            <h2 class="text-center mb-4">Professionals We Recommend</h2>
        </div>
        <!-- / Title -->

        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
            <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- No panel -->
        <div v-if="!isLoading && !panel" class="text-center">
            <p>Nothing to show here.</p>
        </div>
        <!-- / No panel -->

        <!-- Panel -->
        <div v-if="!isLoading && panel">
            <div class="row">
                <div class="col-12 col-md-4 mb-3" v-for="(panelLink, i) in panel" :key="`panel-link-${i}`">
                    <div class="card card-body bg-secondary panel-lawyer-card mb-3">
                        <div class="top">
                            <router-link :to="`/landing/${panelLink.id}`">
                                <panel-configuration-logo
                                    :panel-configuration-id="panelLink.panel_configuration_id"></panel-configuration-logo>
                            </router-link>

                            <!-- <div class="title"> -->
                            <!-- <h6 class="card-title"> -->
                            <!-- {{ panelLink.panel_configuration.display_name }} -->
                            <!-- </h6> -->
                            <!-- </div> -->

                            <div class="description">
                                <p>
                                    {{ panelLink.panel_configuration.description }}
                                </p>
                            </div>

                        </div>
                        <div class="bottom"></div>

                        <!-- <a href="#" class="btn btn-primary btn-block w-100">Start
                            Conversation</a> -->
                        <panel-start-conversation-button-and-modal-unauthenticated
                            :panel-configuration="panelLink.panel_configuration"></panel-start-conversation-button-and-modal-unauthenticated>

                        <div class="row">
                            <div class="col-lg-6 mt-3">
                                <panel-pricing-button-and-modal :panel-configuration="panelLink.panel_configuration">
                                </panel-pricing-button-and-modal>
                            </div>
                            <div class="col-lg-6 mt-3">
                                <router-link class="btn btn-dark btn-block w-100" :to="`/landing/${panelLink.id}`">
                                    About Us
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- / Panel -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '../../components/Spinner.vue'
import axios from 'axios';
import PanelConfigurationLogo from '../../components/PanelConfiguration/PanelConfigurationLogo.vue'
import PanelPricingButtonAndModal from '../../components/PanelConfiguration/PanelPricingButtonAndModal.vue';
import PanelStartConversationButtonAndModalUnauthenticated from '../../components/PanelConfiguration/PanelStartConversationButtonAndModalUnauthenticated.vue';

export default {
    data() {
        return {
            isLoading: false,
            panel: null
        }
    },

    components: { Spinner, PanelConfigurationLogo, PanelPricingButtonAndModal, PanelStartConversationButtonAndModalUnauthenticated },

    created() {
        this.fetch();
    },

    computed: {
        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfiguration: 'whitelabelConfiguration'
        })
    },

    methods: {
        fetch() {
            let vm = this;
            vm.isLoading = true;
            axios.get(window.API_BASE + '/whitelabel-configurations/' + vm.whitelabelConfiguration.id + '/panel')
                .then(function (response) {
                    vm.panel = response.data;
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    vm.isLoading = false;
                });
        }
    }
}
</script>

<style scoped lang="scss">
.panel-lawyer-card {
    text-align: center;
    height: 100%;

    display: flex;
    flex-direction: column;

    .top {
        flex-grow: 1;
    }

    .bottom {
        flex-shrink: 1;
    }

    .description {
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 15px;
    }
}
</style>