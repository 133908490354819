<template>
    <div class="signup-overlay d-flex align-items-center justify-content-center">
        <div class="signup-overlay-inner bg-white p-4 pb-1 rounded shadow">
            <form>
                <h2 class="text-center mb-4">Create Your Account</h2>
                <div class="mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input :disabled="isSaving" v-model="name" type="text" class="form-control" id="name" placeholder="Enter your name">
                </div>
                <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input :disabled="isSaving" v-model="email" type="email" class="form-control" id="email" placeholder="Enter your email">
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input :disabled="isSaving" v-model="password" type="password" class="form-control" id="password" placeholder="Enter a password">
                </div>
                <button :disabled="isSaving"
                @click.prevent="createAccount"
                 type="submit" class="btn btn-primary w-100">
                    {{ isSaving ? 'Creating Account...' : 'Create Account' }}
                </button>

                <div class="signup-footer">
                    <p class="text-center mt-3">Already Have An Account? <br /><router-link to="/login">Click Here To
                            Login</router-link></p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import Forms from '../../mixins/Forms';

export default {
    data() {
        return {
            name: '',
            email: '',
            password: '',

            isSaving: false
        }
    },

    computed: {
        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfigurationId: 'whitelabelConfigurationId'
        })
    },

    mixins: [Forms],

    methods: {
        ...mapActions('auth', {
            handleUserRegistered: 'handleUserRegistered'
        }),

        createAccount() {
            let vm = this;
            vm.isSaving = true;
            axios.post(window.API_BASE + '/client-register', {
                name: vm.name,
                email: vm.email,
                password: vm.password,
                password_confirmation: vm.password,
                wcid: vm.whitelabelConfigurationId
            }).then(response => {
                vm.isSaving = false;
                vm.handleUserRegistered(response.data);
                vm.$router.push('/');
            }).catch(e => {
                console.log(e);
                vm.isSaving = false;
                vm.setAndNotifyErrors(e);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.signup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    z-index: 99999 !important;
    /* Ensure it is above other content */

    padding: 20px;
}

.signup-overlay-inner {
    max-width: 450px;
    width: 100%;
}

h2 {
    font-size: 24px;
    font-weight: 600;
}

.signup-footer {
    font-size: 15px;
    margin-top: 25px;

    a {
        text-decoration: none;
        display: block;
        margin-top: 3px;
    }
}
</style>