<template>
    <section class="bg-dark pb-3 pt-5">
        <div class="container py-5">
            <div class="row align-items-center mt-2 mt-md-0 pt-md-4 py-lg-2">
                <div class="col-md-8 offset-md-2 order-md-1 text-center">
                    <h1 class="display-5 pt-2 text-light pb-0 mb-4 mb-lg-4">Welcome to <span class="text-primary">{{
                        whitelabelConfiguration ? whitelabelConfiguration.display_name + ' Professionals' : 'Syndesi' }}</span>
                    </h1>


                    <p class="text-white ">
                        To find a professional to assist you, please choose from one of our panel professionals
                        below, or search our pool of professionals using the "Find a Professional" button.
                    </p>

                    <!-- Calls to action -->
                    <!--            <router-link v-if="isAdvisor"-->
                    <!--                         to="/advisors/find-a-lawyer"-->
                    <!--                         class="btn btn-primary btn-lg rounded-pill w-100 w-lg-auto ms-auto mb-4">-->
                    <!--              <i class="fi-search me-2"></i>-->
                    <!--              Find a lawyer-->
                    <!--            </router-link>-->
                    <!-- / Calls to action -->

                    <!-- Stats -->
                    <!--            <stats></stats>-->
                    <!-- / Stats -->
                </div>
            </div>
        </div>
    </section>
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
        <div class="container pt-5 pb-2 mb-4 mb-md-5 pb-5" style="padding-top: 50px !important;">

            <div class="row">
                <div class="col-md-12">

                    <signup-overlay v-if="!loggedIn"></signup-overlay>

                    <!-- Lawyers We Recommend -->
                    <lawyers-we-recommend v-if="whitelabelConfiguration"></lawyers-we-recommend>
                    <!-- / Lawyers We Recommend -->

                    <!-- Can't find what you're looking for? -->
                    <div class="text-center mt-5">
                        <h2 class="text-center mb-4">Can't Find What You're Looking For?</h2>
                        <p class="text-center">
                            <router-link to="/guests/find-a-lawyer"
                                class="btn btn-primary btn-lg rounded-pill w-100 w-lg-auto ms-auto mb-4">
                                <i class="fi-search me-2"></i>
                                Find a Professional
                            </router-link>
                        </p>
                    </div>
                    <!-- / Can't find what you're looking for? -->

                    <!-- Powered by Syndesi -->
                     <!-- <div class="text-center mt-5">
                        Powered by <a target="_blank" href="https://www.syndesi.network/"><img style="height: 20px; margin-left: 5px;" src="/img/logo-light.png" alt="Syndesi"></a>

                        <div class="mt-2">
                            <small>All rights reserved.</small>
                        </div>

                        <div class="links mt-2">
                            <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTdcHVG0V7KYMHOoPP3ytO1TArIV9u30auBHyXx81anqt5D1uyaYh2W-XGGOBlI7-v4Hely1HXoBWy2/pub">
                                <small>Terms & Conditions</small>
                            </a>

                            <a class="ms-3" target="_blank" href="https://docs.google.com/document/d/1z7Ca0UK5UyW6CHzZJMhvSzyPZZHfK5Y16NABo7ISuyw/edit">
                                <small>Privacy Policy</small>
                            </a>
                        </div>
                     </div> -->
                    <!-- / Powered by Syndesi -->

                </div>
            </div>

        </div>
    </section>
</template>
  
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Spinner from "../components/Spinner";
import LawyersWeRecommend from "./Landing/LawyersWeRecommend.vue";
import SignupOverlay from "./Landing/SignupOverlay.vue";
import { mapGetters, mapActions } from 'vuex'
import * as amplitude from '@amplitude/analytics-browser';

export default {
    components: { Breadcrumb, Spinner, LawyersWeRecommend, SignupOverlay },
    data() {
        return {
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
        if (!this.whitelabelConfiguration && this.$route.query.company) {
            this.$router.push('/company/' + this.$route.query.company);
        }
        if (this.whitelabelConfiguration && this.$route.query.company && this.whitelabelConfiguration.slug !== this.$route.query.company) {
            this.$router.push('/company/' + this.$route.query.company);
        }
        if (this.whitelabelConfiguration) {
            amplitude.track('Whitelabel Landing Page Viewed', {
                whitelabel_id: this.whitelabelConfiguration.id,
                whitelabel_configuration_name: this.whitelabelConfiguration.display_name
            });
        }
    },
    methods: {
    },
    computed: {
        ...mapGetters('auth', {
            loggedIn: 'loggedIn'
        }),

        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfiguration: 'whitelabelConfiguration'
        })
    },
    setup() {

    },
}
</script>
  
  
<style scoped lang="scss">
.text-primary {
    color: rgb(var(--primary-colour)) !important;
}
</style>